import { styled } from 'styled-components'
import Element from './Element'

const Img = styled.img`
    position: absolute;
    z-index: -1;
    width: 500px;
    margin-top: -200px;
`

export default function Equipment() {
    return (
        <Element heading="Equipment">
            <div>
                <input type="checkbox" /> <label>Poly dot, tape or cones</label>
            </div>
            <div>
                <input type="checkbox" />{' '}
                <label>2 x foam soccer balls (20.3 - 25.4cms)</label>
            </div>
            <div>
                {' '}
                <input type="checkbox" />
                <label> 2 x foam tennis ball sized ball </label>
            </div>
            <div>
                {' '}
                <input type="checkbox" /> 2 x 10.2 cm plastic/foam ball{' '}
            </div>
            <div>
                {' '}
                <input type="checkbox" /> Video equipment / Device{' '}
            </div>
            <div>
                {' '}
                <input type="checkbox" /> Tripod or table{' '}
            </div>
            <div>
                {' '}
                <input type="checkbox" /> Charging cords{' '}
            </div>
            <Img src="/images/home/equipment.png" />
        </Element>
    )
}
