const apiUrl = process.env.REACT_APP_API_URL

export default async function postClass(body: ClassRequest) {
    const response = await fetch(`${apiUrl}/class`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    })

    const registerResponse: RegisterResponse = await response.json()

    return registerResponse
}

interface RegisterResponse {
    classId: number
}

interface ClassRequest {
    classname: string
    teacher_id: number
}
