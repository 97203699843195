import { useState } from 'react'
import { styled } from 'styled-components'
import postClass from '../libs/class-service'
import FileUploader from './FileUploader'
import { useNavigate } from 'react-router-dom'

const Wrapper = styled.div`
    display: flex;
    gap: 30px;
    padding: 30px;
    flex-direction: column;
`

const DownloadForm = styled.div`
    border: 1px solid #a4a2a1;
    padding: 10px;
`

const UploadForm = styled.div`
    border: 1px solid #a4a2a1;
    padding: 30px;
`

const InputLabel = styled.p`
    color: #a4a2a1;
    padding: 10px 0;
`

const InputBox = styled.input`
    height: 40px;
    width: 100%;
    border: 1px solid #a4a2a1;
`

const Button = styled.input`
    padding: 10px;
    width: 100%;
    margin: 20px 0;
    background-color: #115ff5;
    border: none;
    border-radius: 5px;
    color: white;
`

interface Props {
    teacherId: number
    classListUrl: string
}

export default function StudentUploader({ teacherId, classListUrl }: Props) {
    const [classname, setClassName] = useState<string>('')
    const [classId, setClassId] = useState<number>(0)
    const [status, setStatus] = useState('')
    async function handleCreateClass() {
        const body = {
            classname: classname,
            teacher_id: teacherId,
        }
        const { classId } = await postClass(body)

        setClassId(classId)
        setStatus('success')
    }

    const navigate = useNavigate()
    function handleGetStart() {
        navigate('/')
    }

    return (
        <Wrapper>
            <div>
                <InputLabel>Class Name</InputLabel>
                <InputBox
                    type="text"
                    value={classname}
                    onChange={(e) => setClassName(e.target.value)}
                />
                <Button
                    type="button"
                    value="Create Class"
                    onClick={handleCreateClass}
                />
                <br />
                {status === 'success' && 'Class is created!'}
            </div>
            <div>
                <InputLabel>Student Information Form</InputLabel>
                <DownloadForm>
                    <a href={classListUrl} download>
                        Click to download blank student .csv file
                    </a>
                </DownloadForm>
            </div>
            <div>
                <InputLabel>Uploading Student Information Form</InputLabel>
                <UploadForm>
                    <FileUploader classId={classId} />
                </UploadForm>
            </div>
            <Button type="button" onClick={handleGetStart} value="Start" />
        </Wrapper>
    )
}
