const apiUrl = process.env.REACT_APP_API_URL

export default async function uploadStudents(body: FormData, classId: number) {
    const videoResponse = await fetch(`${apiUrl}/teacher/${classId}`, {
        method: 'POST',
        body: body,
    })
    const videoJsonResponse: VideoResponse = await videoResponse.json()

    return videoJsonResponse
}

interface VideoResponse {
    success: boolean
}
