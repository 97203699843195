import Skills from '../components/Skills'
import VideoRecorder from '../components/VideoRecorder'
import styled from 'styled-components'
import { useContext, useEffect, useState } from 'react'
import postVideo, { processVideo } from '../libs/video-service'
import postAssessment from '../libs/assessment-service'
import getStudents, { Student } from '../libs/student-service'
import { Class } from '../libs/login-service'
import StudentTable from '../components/StudentTable'
import ClassSelector from '../components/ClassSelector'
import { RxCross1 } from 'react-icons/rx'
import { Batch } from '../libs/batch-service'
import { useNavigate } from 'react-router-dom'
import { ClassContext } from '../App'
import {
    DoubleButtonModal,
    NoButtonModal,
    SingleButtonModal,
} from '../components/shared/Modal'
import { ConfirmSubmitModal } from '../components/assessment/ConfirmSubmitModal'
import { AiOutlineLoading3Quarters } from 'react-icons/ai'

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 30px;
    padding: 30px 0;
`
const Hero = styled.div`
    height: 200px;
    width: 90%;
    background-color: #e7effe;

    display: flex;
    justify-content: space-between;

    box-sizing: border-box;
    border-radius: 8px;
`

const HeroImage = styled.img`
    height: 200px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
`

const AssessmentWrapper = styled.div`
    border: 1px solid grey;
    border-radius: 10px;
    width: 90%;
`

const AssessmentContent = styled.div`
    display: flex;
    justify-content: space-between;
    align-item: center;
    padding: 20px;
`

const StudnetPic = styled.img``
const AssessmentHeader = styled.div`
    padding: 20px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid black;
`

const Cross = styled(RxCross1)`
    position: static;
    top: 5%;
    padding: 10px;
    cursor: pointer;
`

const HeroContentWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px;
`

const StudentText = styled.div`
    padding: 10px 0;
`

const Loading = styled(AiOutlineLoading3Quarters)`
    animation: spin 1s linear infinite;
    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`
interface Prop {
    setTitle: React.Dispatch<React.SetStateAction<string>>
}

const getDate = () => {
    var tody = new Date()
    var year = tody.getFullYear()
    var month = tody.getMonth() + 1
    var day = tody.getDate()
    var hour = tody.getHours()
    var min = tody.getMinutes()
    var second = tody.getSeconds()

    return `${year}_${month}_${day}_${hour}_${min}_${second}`
}

export default function Assessment({ setTitle }: Prop) {
    useEffect(() => {
        setTitle('Movement Skill Assessment')
    }, [])
    const classes = useContext(ClassContext)

    const [isHeroDisplay, setIsHeroDisplay] = useState(true)
    const [skill, setSkill] = useState<string>('')
    const [selectedClass, setSelectedClass] = useState<Class>(classes[0])
    const [batch, setBatch] = useState<Batch | undefined>(
        selectedClass?.batches[0]
    )
    const [res, setRes] = useState<any>()
    const [mediaBlobUrl, setMedisBlobUrl] = useState<string | null>()

    const [student, setStudent] = useState<Student | null>(null)
    const [status, setStatus] = useState<
        'start' | 'confirm' | 'uploading' | 'completed' | 'error' | 'default'
    >('default')

    useEffect(() => {
        setSelectedClass(classes[0])
        setBatch(classes[0]?.batches[0])
    }, [classes])
    function handleSubmitClick(mediaBlobUrl: string | null) {
        if (!mediaBlobUrl || !student || !batch) {
            return
        }

        setMedisBlobUrl(mediaBlobUrl)

        setStatus('start')
    }

    const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms))

    const uploadVideo = async () => {
        if (!mediaBlobUrl || !student || !batch) {
            return
        }
        setStatus('uploading')

        try {
            var body = new FormData()
            const video = await fetch(mediaBlobUrl).then((r) => r.blob())
            const videoName = `${student.id}_${skill}_${getDate()}.mp4`
            const file = new File([video], videoName, {
                type: 'video/mp4',
            })
            body.append('file', file)

            const { videoPath } = await postVideo(body)

            const assessment_id = await postAssessment(
                skill,
                student.id,
                batch.id,
                videoPath
            )

            setStatus('completed')
            await sleep(1000)

            setStatus('default')
            const res = await processVideo(assessment_id)
            setRes(res)
        } catch (e) {
            setStatus('error')
        }
    }

    function handleClassUpdate(c: Class) {
        setSelectedClass(c)
        if (c.batches.length !== 0) {
            setBatch(c.batches[0])
        }
    }

    function handleBadgeClick(student: Student, skill: string) {
        setStudent(student)
        setSkill(skill)
    }

    function handleModalCancel() {
        setStatus('default')
    }

    return (
        <Wrapper>
            {status === 'start' && (
                <ConfirmSubmitModal
                    batch={batch}
                    skill={skill}
                    student={student}
                    handleCancel={handleModalCancel}
                    handleConfirm={uploadVideo}
                />
            )}
            {status === 'uploading' && (
                <NoButtonModal
                    children={
                        <>
                            <Loading />
                            'Uploading...'
                        </>
                    }
                />
            )}
            {status === 'completed' && (
                <NoButtonModal children={'Upload success!'} />
            )}
            {status === 'error' && (
                <SingleButtonModal
                    children={
                        'Upload error! Please try again. If still fail, please contract MNG team.'
                    }
                    onCancel={() => {
                        setStatus('default')
                    }}
                />
            )}
            {isHeroDisplay && (
                <Hero>
                    <HeroImage src="images/assessment/hero.png" />
                    <HeroContentWrapper>
                        <h2>
                            Please refer back to the ‘assessment set up’ to
                            check you have everything ready
                        </h2>
                    </HeroContentWrapper>
                    <Cross onClick={() => setIsHeroDisplay(false)} />
                </Hero>
            )}

            <AssessmentWrapper>
                <AssessmentHeader>
                    <div>
                        <b>Assessment Window </b>
                        <br />
                        <small>Record/upload student videos</small>
                    </div>
                    <div>
                        <p>Select Skills</p>
                        <Skills skill={skill} setSkill={setSkill} />
                    </div>
                </AssessmentHeader>
                <AssessmentContent>
                    <VideoRecorder handleSubmit={handleSubmitClick} />
                    <div>
                        <StudnetPic src="images/assessment/profile.png" />
                        <StudentText>
                            Student Name: {student && student.first_name}{' '}
                            {student && student.last_name}
                        </StudentText>
                        <StudentText>
                            Student ID: {student && student.student_id}
                        </StudentText>
                        <StudentText>
                            Class: {selectedClass && selectedClass.name}
                        </StudentText>
                        <StudentText>Year: 2022</StudentText>
                        <StudentText>School: Melbourne High School</StudentText>
                    </div>
                </AssessmentContent>
            </AssessmentWrapper>

            <ClassSelector
                classes={classes}
                setSelectedClass={handleClassUpdate}
                selectedClass={selectedClass}
                setBatch={setBatch}
            />

            <StudentTable
                selectedClass={selectedClass}
                batch={batch}
                setStudent={setStudent}
                badgeClick={handleBadgeClick}
            />
        </Wrapper>
    )
}
