import { useState } from 'react'
import { styled } from 'styled-components'

interface Prop {
    setTitle: React.Dispatch<React.SetStateAction<string>>
}
const Button = styled.div<{ selected: boolean }>`
    width: 120px;
    display: flex;
    justify-content: center;
    padding: 10px;
    border-radius: 5px;
    background-color: #e7effe;
    color: #115ff5;

    ${({ selected }) =>
        selected &&
        `
    background-color: #115ff5; 
    color: white;`}
`

const ButtonsWrapper = styled.div`
    display: flex;
`

const Wrapper = styled.div`
    padding: 50px;
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 30px;
`

const Img = styled.img`
    width: 500px;
`

const data = [
    {
        id: 0,
        label: 'Foundation',
        imgUrl: './images/education/foundation.png',
        text: {
            p1: 'Practise fundamental movement skills and movement sequences using different body parts and in response to stimuli in indoor, outdoor and aquatic settings ',
            linkText: '(VCHPEM064)',
            link: 'https://victoriancurriculum.vcaa.vic.edu.au/Curriculum/ContentDescription/VCHPEM064',
            p2: 'Students perform fundamental movement skills.',
        },
    },
    {
        id: 1,
        label: 'Level 1-2',
        imgUrl: './images/education/level-1-2.png',
        text: {
            p1: 'Perform fundamental movement skills in different movement situations in indoor, outdoor and aquatic settings ',
            linkText: '(VCHPEM080)',
            link: 'https://victoriancurriculum.vcaa.vic.edu.au/Curriculum/ContentDescription/VCHPEM080',
            p2: 'Students demonstrate fundamental movement skills in different movement situations.',
        },
    },
    {
        id: 2,
        label: 'Level 3-4',
        imgUrl: './images/education/level-3-4.png',
        text: {
            p1: 'Practise and refine fundamental movement skills in different movement situations in indoor, outdoor and aquatic settings ',
            linkText: '(VCHPEM097)',
            link: 'https://victoriancurriculum.vcaa.vic.edu.au/Curriculum/ContentDescription/VCHPEM097',
            p2: 'Students perform fundamental movement skills.',
        },
    },
    {
        id: 3,
        label: 'Level 5-6',
        imgUrl: './images/education/level-5-6.png',
        text: {
            p1: 'Practise specialised movement skills and apply them in different movement situations in indoor, outdoor and aquatic settings ',
            linkText: '(VCHPEM115)',
            link: 'https://victoriancurriculum.vcaa.vic.edu.au/Curriculum/ContentDescription/VCHPEM115',
            p2: 'Students perform specialised movement skills.',
        },
    },
    {
        id: 4,
        label: 'Level 7-8',
        imgUrl: './images/education/level-7-8.png',
        text: {
            p1: 'Use feedback to improve body control and coordination when performing specialised movement skills ',
            linkText: '(VCHPEM133)',
            link: 'https://victoriancurriculum.vcaa.vic.edu.au/Curriculum/ContentDescription/VCHPEM133',
            p2: 'Students demonstrate control and accuracy when performing specialised movement skills.',
        },
    },
    {
        id: 5,
        label: 'Level 9-10',
        imgUrl: './images/education/level-9-10.png',
        text: {
            p1: 'Perform and refine specialised movement skills in challenging movement situations ',
            linkText: '(VCHPEM152)',
            link: 'https://victoriancurriculum.vcaa.vic.edu.au/Curriculum/ContentDescription/VCHPEM152',
            p2: 'Students apply criteria to make judgments about and refine their own and others’ specialised movement skills and movement performances.',
        },
    },
]

const ContentWrapper = styled.div`
    display: flex;
    border-radius: 8px;
    background-color: #fafafa;
`

const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 60px;
    gap: 20px;
`

export default function Education({ setTitle }: Prop) {
    setTitle('Education')
    const [selectedButton, setSelectedButton] = useState(data[0])

    return (
        <div>
            <Wrapper>
                <h1>Link to Curriculum</h1>

                <ButtonsWrapper>
                    {data.map((d, i) => {
                        return (
                            <Button
                                key={i}
                                selected={selectedButton.id === d.id}
                                onClick={() => setSelectedButton(d)}
                            >
                                {d.label}
                            </Button>
                        )
                    })}
                </ButtonsWrapper>
                <ContentWrapper>
                    <Img src={selectedButton.imgUrl} />
                    <TextWrapper>
                        <h1>{selectedButton.label}</h1>
                        <p>
                            {selectedButton.text.p1}
                            <a target="_blank" href={selectedButton.text.link}>
                                {selectedButton.text.linkText}
                            </a>
                        </p>
                        <h4>Achievement standard</h4>
                        <p>{selectedButton.text.p2}</p>
                    </TextWrapper>
                </ContentWrapper>
            </Wrapper>
        </div>
    )
}
