import { useRef, useEffect, useState } from 'react'
import { ReactMediaRecorder } from 'react-media-recorder'
import { styled } from 'styled-components'

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const ButtonWrapper = styled.div`
    margin-top: 30px;
    width: 80%;
    display: flex;
    justify-content: space-between;
`

const VideoWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const VideoPlaceholder = styled.video`
    width: 800px;
    height: 500px;
    background-color: #e7effe;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
`

const RecordButton = styled.button`
    all: unset;
    cursor: pointer;
    width: 200px;
    background-color: #00b88c;
    color: white;
    padding: 10px;
    display: flex;
    justify-content: center;
    border-radius: 8px;

    &:hover {
        cursor: pointer;
        background-color: #00b88c90;
    }
`

const StopButton = styled.button`
    all: unset;
    cursor: pointer;
    width: 200px;
    background-color: #ed6a5e;
    color: white;
    padding: 10px;
    display: flex;
    justify-content: center;
    border-radius: 8px;
    &:hover {
        cursor: pointer;
        background-color: #ed6a5e90;
    }
`
const SubmitButton = styled.button`
    all: unset;
    cursor: pointer;
    width: 200px;
    background-color: #115ff5;
    color: white;
    padding: 10px;
    display: flex;
    justify-content: center;
    border-radius: 8px;
    &:hover {
        cursor: pointer;
        background-color: #115ff590;
    }
`

interface Props {
    handleSubmit: (mediaBlobUrl: string | null) => void
}

export default function VideoRecorder({ handleSubmit }: Props) {
    const [initialPreviewStream, setInitialPreviewStream] =
        useState<MediaStream>()

    const getInitialPreviewStream = async (constraints: any) => {
        return new Promise<MediaStream>((resolve, reject) => {
            return navigator.mediaDevices
                .getUserMedia(constraints)
                .then((stream) => {
                    resolve(stream)
                })
                .catch((err) => {})
        })
    }

    useEffect(() => {
        async function GetPreviewStream() {
            const stream = await getInitialPreviewStream({
                video: true,
            })
            setInitialPreviewStream(stream)
        }

        GetPreviewStream()
    }, [])

    return (
        <>
            <ReactMediaRecorder
                mediaRecorderOptions={{
                    mimeType: 'video/mp4',
                }}
                video
                render={({
                    status,
                    startRecording,
                    stopRecording,
                    clearBlobUrl,
                    mediaBlobUrl,
                    previewStream,
                }) => (
                    <Wrapper>
                        <VideoWrapper>
                            {status === 'idle' && initialPreviewStream && (
                                <VideoPreview stream={initialPreviewStream} />
                            )}
                            {status !== 'stopped' && (
                                <VideoPreview stream={previewStream} />
                            )}
                            {status === 'stopped' && mediaBlobUrl && (
                                <VideoPlaceholder src={mediaBlobUrl} controls />
                            )}
                        </VideoWrapper>
                        {status === 'idle' && 'Previewing'}
                        {status === 'recording' && 'Recording'}
                        {status === 'stopped' && 'Stopped'}
                        <ButtonWrapper>
                            <RecordButton onClick={startRecording}>
                                Record
                            </RecordButton>
                            <StopButton onClick={stopRecording}>
                                Stop
                            </StopButton>
                            <SubmitButton
                                onClick={() => {
                                    handleSubmit(mediaBlobUrl)
                                }}
                            >
                                Submit
                            </SubmitButton>
                        </ButtonWrapper>
                    </Wrapper>
                )}
            />
        </>
    )
}

const VideoPreview = ({ stream }: { stream: MediaStream | null }) => {
    const videoRef = useRef<HTMLVideoElement>(null)

    useEffect(() => {
        if (videoRef.current && stream) {
            videoRef.current.srcObject = stream
        }
    }, [stream])
    if (!stream) {
        return null
    }
    return <VideoPlaceholder ref={videoRef} autoPlay controls />
}
