const apiUrl = process.env.REACT_APP_API_URL

export default async function postAssessment(
    skill: string,
    studentId: string,
    batchId: number,
    videoPath: string
) {
    const data = {
        skill: skill,
        studentId: parseInt(studentId),
        batchId: batchId,
        videoPath: videoPath,
    }

    const assessmentResponse = await fetch(`${apiUrl}/assessment`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    })
    const { assessment_id } = await assessmentResponse.json()

    return assessment_id
}

export async function getAssessmentsByStudentId(studentId: string) {
    const res = await fetch(`${apiUrl}/assessment?studentId=${studentId}`)
    const response: Assessment[] = await res.json()

    return response
}

export interface Assessment {
    id: number
    skill: string
    timestamp: string
    videoPath: string
    result: string
    batch: string
    finalScore: number
    detailScore: DetailScore[]
}

interface DetailScore {
    label: string
    score: number
}
