import { Batch } from './batch-service'

const apiUrl = process.env.REACT_APP_API_URL

export async function postLogin(body: LoginRequest) {
    const loginResponse = await fetch(`${apiUrl}/login`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    })
    const loginJsonResponse: LoginResponse = await loginResponse.json()

    return loginJsonResponse
}

export async function postLogout(body: LogoutRequest) {
    const response = await fetch(`${apiUrl}/logout`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    })
    const loginJsonResponse: LoginResponse = await response.json()

    return loginJsonResponse
}

export async function getProfile(id: number) {
    const response = await fetch(`${apiUrl}/me/${id}`, {
        method: 'Get',
        headers: {
            'Content-Type': 'application/json',
        },
    })
    const loginJsonResponse: ProfileResponse = await response.json()

    return loginJsonResponse
}

interface LoginResponse {
    success: boolean
    token: string
    userId: number
}

interface ProfileResponse {
    first_name: string
    last_name: string
    classes: Class[]
}

export interface Class {
    id: number
    name: string
    batches: Batch[]
}

interface LoginRequest {
    email: string
    password: string
}
interface LogoutRequest {
    email: string
}
