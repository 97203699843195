import { useEffect, useState } from 'react'
import styled from 'styled-components'
import getSkills, { Skill } from '../libs/skill-service'
import StudentBarChart from './student/StudentBarChart'
import { Assessment } from '../libs/assessment-service'
import ReactPlayer from 'react-player'
import { getOutUrl, getVideoUrl } from '../libs/video-service'
import SkillLabel from './SkillLabel'

const Wrapper = styled.label`
    padding: 30px;
`

const SkillWrapper = styled.div`
    display: flex;
    margin: 30px;
`

const SkillDiagramWrapper = styled.div`
    padding: 30px;
`

const Button = styled.div<{ selected: boolean }>`
    width: 120px;
    display: flex;
    justify-content: center;
    padding: 10px;
    border-radius: 5px;
    background-color: #e7effe;
    color: #115ff5;

    ${({ selected }) =>
        selected &&
        `
    background-color: #115ff5; 
    color: white;`}
`
const SelectedAssessmentWrapper = styled.div`
    padding: 30px;
    display: flex;
    flex-direction: column;
    gap: 20px;
`

const VideosWrapper = styled.div`
    display: flex;
`

interface AssessmentsInfoProps {
    assessments: Assessment[]
}

interface Data {
    skill: string
    result: Result[]
}

interface Result {
    batch: string
    score: number
    id: number
}

export default function AssemssmentsInfo({
    assessments,
}: AssessmentsInfoProps) {
    const [skills, setSkills] = useState<Skill[]>([])
    const [skill, setSkill] = useState<string>('')
    const [selectedAssessment, setSelectedAssessment] =
        useState<Assessment | null>(null)
    const [videoUrl, setVideoUrl] = useState<string>('')
    const [outputUrl, setOutputUrl] = useState<string>('')

    useEffect(() => {
        const fetchSkills = async () => {
            const { skills } = await getSkills()
            setSkills(skills)
            setSkill(skills[0].name)
        }
        fetchSkills()
    }, [])

    function handleSkillSelect(skillName: string) {
        setSkill(skillName)
        setSelectedAssessment(null)
    }

    const dataTest: Data[] = [
        {
            skill: 'Jump',
            result: [],
        },
        {
            skill: 'Catch',
            result: [],
        },
        {
            skill: 'Skip',
            result: [],
        },
        {
            skill: 'Hop',
            result: [],
        },
        {
            skill: 'Sidestep',
            result: [],
        },
        {
            skill: 'Kick',
            result: [],
        },
        {
            skill: 'Throw',
            result: [],
        },
    ]

    assessments.forEach((a) => {
        const el = dataTest.find((dt) => a.skill === dt.skill)
        el?.result.push({
            batch: a.batch,
            score: a.finalScore,
            id: a.id,
        })
    })

    const skillData = dataTest.find((d) => d.skill === skill)

    let data: any

    if (skillData) {
        data = {
            labels: skillData.result.map((r) => r.batch),
            datasets: [
                {
                    label: skillData.skill,
                    data: skillData.result.map((r) => r.score),
                },
            ],
        }
    }

    function handleBarClick(dataIndex: number) {
        if (!skillData) {
            return
        }
        const assessmentId = skillData.result[dataIndex].id
        const assessment = assessments.find((a) => a.id === assessmentId)

        if (!assessment) {
            return
        }

        setSelectedAssessment(assessment)

        const videoUrl = getVideoUrl(assessment.videoPath)
        const outputUrl = getOutUrl(
            `/outputs/animation_catch_participant_5002_allgood_test.mp4`
        )
        setVideoUrl(videoUrl)
        setOutputUrl(outputUrl)
    }

    return (
        <Wrapper>
            <SkillWrapper>
                {skills.map((s, i) => {
                    return (
                        <Button
                            key={i}
                            onClick={() => handleSkillSelect(s.name)}
                            selected={s.name === skill}
                        >
                            {s.name}
                        </Button>
                    )
                })}
            </SkillWrapper>

            <SkillDiagramWrapper>
                {data && !selectedAssessment && (
                    <StudentBarChart
                        heading={`Criteria achieved for ${skillData?.skill ?? ''}`}
                        data={data}
                        handleBarClick={handleBarClick}
                    />
                )}
            </SkillDiagramWrapper>

            {selectedAssessment && (
                <SelectedAssessmentWrapper>
                    <div>Batch - {selectedAssessment.batch}</div>
                    <VideosWrapper>
                        <ReactPlayer controls url={videoUrl} />
                    </VideosWrapper>
                    <div>
                        <DetailScoreTopLine>
                            <b>Criteria</b>
                            <b>Score</b>
                        </DetailScoreTopLine>
                        {selectedAssessment.detailScore.map((s, i) => {
                            return (
                                <DetailScoreLine key={i}>
                                    <div>{s.label}</div>
                                    <div>{s.score}</div>
                                </DetailScoreLine>
                            )
                        })}
                        <DetailScoreLine>
                            <b>Overall Skill Score</b>
                            <div>{selectedAssessment.finalScore}</div>
                        </DetailScoreLine>
                        <DetailScoreLine>
                            <b>Skill Standard </b>
                            <SkillLabel status={selectedAssessment.result} />
                        </DetailScoreLine>
                        <DetailScoreBottomLine />
                    </div>
                </SelectedAssessmentWrapper>
            )}
        </Wrapper>
    )
}

const DetailScoreTopLine = styled.div`
    border: 1px solid grey;
    border-bottom: none;
    background-color: #fafafa;
    padding: 10px 30px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    display: flex;
    justify-content: space-between;
`

const DetailScoreLine = styled.div`
    border: 1px solid grey;
    border-bottom: none;
    padding: 10px 30px;
    display: flex;
    justify-content: space-between;
`
const DetailScoreBottomLine = styled.div`
    border: 1px solid grey;
    background-color: #fafafa;
    padding: 15px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
`
