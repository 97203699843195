const apiUrl = process.env.REACT_APP_API_URL

export default async function postRegister(body: RegisterRequest) {
    const response = await fetch(`${apiUrl}/register`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    })

    const registerResponse: RegisterResponse = await response.json()

    return registerResponse
}

interface RegisterResponse {
    success: boolean
    accountId: number
}

interface RegisterRequest {
    email: string
    password: string
    firstname: string
    lastname: string
    role: string
}
