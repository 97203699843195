import { styled } from 'styled-components'
import { Student } from '../libs/student-service'

interface Props {
    student: Student
}

const Wrapper = styled.div`
    display: flex;
    gap: 30px;
    padding: 30px;
`

const StudnetPic = styled.img`
    width: 180px;
`

const StudentName = styled.p`
    font-style: normal;
    font-weight: 400;
    font-size: 30px;

    color: rgba(17, 95, 245, 0.9);
`

const StudentText = styled.div`
    padding: 10px 0;
`

export default function StudentInfo({ student }: Props) {
    return (
        <Wrapper>
            <StudnetPic src="/images/assessment/profile.png" />
            <div>
                <StudentName>
                    {student.first_name} {student.last_name}
                </StudentName>
                <StudentText>Student ID: {student.id}</StudentText>
                <StudentText>Gender: {student.gender}</StudentText>
                <StudentText>Year: 2022</StudentText>
                <StudentText>School: Melbourne High School</StudentText>
            </div>
        </Wrapper>
    )
}
