import styled from 'styled-components'

const Processing = styled.div`
    background-color: #ffb6c1;
    color: #ff1493;
    border-radius: 5px;
    padding: 2px;

    &:hover {
        cursor: pointer;
    }
`

const Progressing = styled.div`
    background-color: #fbf6e2;
    color: #f4bd50;
    border-radius: 5px;
    padding: 2px;

    &:hover {
        cursor: pointer;
    }
`

const Achieving = styled.div`
    background-color: rgba(1, 209, 159, 0.2);
    color: #00b88c;
    border-radius: 5px;
    padding: 2px;

    &:hover {
        cursor: pointer;
    }
`

const Developeing = styled.div`
    background-color: #fbe1df;
    color: #ed6a5e;
    border-radius: 5px;
    padding: 2px;

    &:hover {
        cursor: pointer;
    }
`

const NotRecording = styled.div`
    background-color: #717171;
    color: white;
    border-radius: 5px;
    padding: 2px;

    &:hover {
        cursor: pointer;
        background-color: #71717190;
    }
`

const Error = styled.div`
    background-color: #ed6a5e;
    color: white;
    border-radius: 5px;
    padding: 2px;

    &:hover {
        cursor: pointer;
        background-color: #ed6a5e90;
    }
`
interface SkillLabelProps {
    status: string | undefined
}

export default function SkillCell({ status }: SkillLabelProps) {
    if (status === 'processing') {
        return <Processing>Processing</Processing>
    }

    if (status === 'progressing') {
        return <Progressing>Progressing</Progressing>
    }

    if (status === 'achieving') {
        return <Achieving>Achieving</Achieving>
    }

    if (status === 'developing') {
        return <Developeing>Developing</Developeing>
    }

    if (status === 'error') {
        return <Error>Error</Error>
    }
    return <NotRecording>No Record</NotRecording>
}
