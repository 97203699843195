import { Link } from 'react-router-dom'
import styled from 'styled-components'
import getStudents, { Student } from '../libs/student-service'
import SkillLabel from './SkillLabel'
import { useEffect, useState } from 'react'
import { Class } from '../libs/login-service'
import { Batch } from '../libs/batch-service'

const StudentTableWrapper = styled.div`
    border: 1px solid grey;
    border-radius: 10px;
    width: 90%;
    display: flex;
    justify-content: center;
    text-align: center;
`

const Table = styled.table`
    width: 100%;
    padding: 20px;
`
const TR = styled.tr``

const TD = styled.td`
    padding: 5px;
`

interface Props {
    setStudent: React.Dispatch<React.SetStateAction<Student | null>>
    badgeClick: (student: Student, skill: string) => void
    selectedClass: Class
    batch: Batch | undefined
}

export default function StudentTable({
    setStudent,
    badgeClick,
    selectedClass,
    batch,
}: Props) {
    const [students, setStudents] = useState<Student[]>([])

    useEffect(() => {
        const fetchStudents = async () => {
            if (!selectedClass || !batch) {
                return
            }

            const { students } = await getStudents(selectedClass.id, batch.id)
            setStudents(students)

            if (students.length === 0) {
                setStudent(null)
            }

            setStudent(students[0])
        }

        const updateStudents = async () => {
            if (!selectedClass || !batch) {
                return
            }

            const { students } = await getStudents(selectedClass.id, batch.id)
            setStudents(students)
        }

        const interval = setInterval(() => {
            try {
                updateStudents()
            } catch (e) {}
        }, 6000)

        fetchStudents()
        return () => clearInterval(interval)
    }, [selectedClass, batch])

    return (
        <StudentTableWrapper>
            <Table>
                <thead>
                    <tr>
                        <th>Class</th>
                        <th>Gender</th>
                        <th>Student ID</th>
                        <th>Name</th>
                        <th>Jump</th>
                        <th>Skip</th>
                        <th>Hop</th>
                        <th>Sidestep</th>
                        <th>Kick</th>
                        <th>Throw</th>
                        <th>Catch</th>
                    </tr>
                </thead>
                <tbody>
                    {students.map((s, i) => {
                        return (
                            <TR key={i} onClick={() => setStudent(s)}>
                                <TD>{s.class_name}</TD>
                                <TD>{s.gender}</TD>
                                <TD>{s.student_id}</TD>
                                <TD>
                                    <Link to={`/student/${s.id}`}>
                                        {s.first_name} {s.last_name}
                                    </Link>
                                </TD>
                                <TD>
                                    <div onClick={() => badgeClick(s, 'Jump')}>
                                        <SkillLabel
                                            status={
                                                s.assessments.find((a) => {
                                                    return a.skill === 'Jump'
                                                })?.result
                                            }
                                        />
                                    </div>
                                </TD>
                                <TD>
                                    <div onClick={() => badgeClick(s, 'Skip')}>
                                        <SkillLabel
                                            status={
                                                s.assessments.find((a) => {
                                                    return a.skill === 'Skip'
                                                })?.result
                                            }
                                        />
                                    </div>
                                </TD>
                                <TD>
                                    <div onClick={() => badgeClick(s, 'Hop')}>
                                        <SkillLabel
                                            status={
                                                s.assessments.find((a) => {
                                                    return a.skill === 'Hop'
                                                })?.result
                                            }
                                        />
                                    </div>
                                </TD>
                                <TD>
                                    <div
                                        onClick={() =>
                                            badgeClick(s, 'Sidestep')
                                        }
                                    >
                                        <SkillLabel
                                            status={
                                                s.assessments.find((a) => {
                                                    return (
                                                        a.skill === 'Sidestep'
                                                    )
                                                })?.result
                                            }
                                        />
                                    </div>
                                </TD>
                                <TD>
                                    <div onClick={() => badgeClick(s, 'Kick')}>
                                        <SkillLabel
                                            status={
                                                s.assessments.find((a) => {
                                                    return a.skill === 'Kick'
                                                })?.result
                                            }
                                        />
                                    </div>
                                </TD>
                                <TD>
                                    <div onClick={() => badgeClick(s, 'Throw')}>
                                        <SkillLabel
                                            status={
                                                s.assessments.find((a) => {
                                                    return a.skill === 'Throw'
                                                })?.result
                                            }
                                        />
                                    </div>
                                </TD>
                                <TD>
                                    <div onClick={() => badgeClick(s, 'Catch')}>
                                        <SkillLabel
                                            status={
                                                s.assessments.find((a) => {
                                                    return a.skill === 'Catch'
                                                })?.result
                                            }
                                        />
                                    </div>
                                </TD>
                            </TR>
                        )
                    })}
                </tbody>
            </Table>
            {students.length === 0 && (
                <div> No students - Plesae import student info</div>
            )}
        </StudentTableWrapper>
    )
}
