import { styled } from 'styled-components'
import ElementWithButton from '../components/ElementWithButton'
import { useState } from 'react'
import { BsArrowLeft, BsArrowRight } from 'react-icons/bs'

const ContentWrapper = styled.div`
    display: flex;
    gap: 15px;
    padding: 10px;
`
const Img = styled.img`
    width: 200px;
`

const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 15px;
`

const HeadingWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`

const HeadingButtonWrapper = styled.div`
    display: flex;
    gap: 10px;
`

const HeadingButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
`

const data = [
    {
        title: 'Horizontal Jump',
        description:
            'Mark off a starting line on the floor. Position the child behind the line. Tell the child to jump far and hold their finishing position. Repeat a second trial.',
        criteria: [
            'Prior to take off both knees are flexed and arms are extended behind back',
            'Arms extend forcefully forward and upward reaching above the head',
            'Both feet come off the floor together and land together',
            'Both arms are forced downward during landing',
        ],
        images: [
            '/images/home/assessments/jump/3.png',
            '/images/home/assessments/jump/2.png',
            '/images/home/assessments/jump/1.png',
        ],
    },
    {
        title: 'Skip',
        description:
            'Tell the child to skip from one cone to the other cone. Repeat a second trial.',
        criteria: [
            'Step forward followed by a hop on the same foot',
            'Arms are flexed and move in opposition to legs to produce force.',
            'Completes four continuous rhythmical alternating skips',
        ],
        images: [
            '/images/home/assessments/skip/1.png',
            '/images/home/assessments/skip/2.png',
            '/images/home/assessments/skip/3.png',
        ],
    },
    {
        title: 'Hop',
        description:
            'Tell the child to hop four times on his/her preferred foot. Repeat a second trial.',
        criteria: [
            'Non-hopping leg swings forward in pendular fashion to produce force.',
            'Foot of non-hopping leg remains behind hopping leg (does not cross in front of)',
            'Arms flex and swing forward to produce force.',
            'Hops four consecutive times on the preferred foot before stopping.',
        ],
        images: [
            '/images/home/assessments/hop/1.png',
            '/images/home/assessments/hop/2.png',
            '/images/home/assessments/hop/3.png',
        ],
    },
    {
        title: 'Sidestep',
        description:
            'Tell the child to slide from one cone to the other cone. Let the child decide which direction to slide in first. Ask the child to slide back to the starting point. Repeat a second trial.',
        criteria: [
            'Body is turned sideways so shoulders remain aligned with the line on the floor (preferred side only)',
            'A step sideways with the lead foot followed by a slide with the trailing foot where both feet come off the surface briefly.',
            'Four continuous slides to the preferred side',
            'Four continuous slides to the non-preferred side',
        ],
        images: [
            '/images/home/assessments/sidestep/1.png',
            '/images/home/assessments/sidestep/2.png',
            '/images/home/assessments/sidestep/3.png',
        ],
    },
    {
        title: 'Kick',
        description:
            'Place the ball on the first line closest to the wall. Tell the child to run up and kick the ball hard towards the wall. Repeat a second trial.',
        criteria: [
            'Rapid, continuous approach to the ball',
            'Child takes an elongated stride or leap just prior to ball contact',
            'Non-kicking foot placed close to the ball',
            'Kicks ball with instep or inside of preferred foot (not the toes)',
        ],
        images: [
            '/images/home/assessments/kick/1.png',
            '/images/home/assessments/kick/2.png',
            '/images/home/assessments/kick/3.png',
        ],
    },
    {
        title: 'Throw',
        description:
            'Have the child stand behind the tape line facing the wall. Tell the child to throw the ball hard at the wall. Repeat a second trial.',
        criteria: [
            'Windup is initiated with a downward movement of hand and arm',
            'Rotates hip and shoulder to a point where the non-throwing side faces the wall',
            'Steps with the foot opposite the throwing hand toward the wall',
            'Throwing hand follows through after the ball release, across the body toward the hip of the non-throwing side',
        ],
        images: [
            '/images/home/assessments/throw/1.png',
            '/images/home/assessments/throw/2.png',
            '/images/home/assessments/throw/3.png',
        ],
    },
    {
        title: 'Catch',
        description:
            'The child stands on one line and the tosser stands on the other line. Toss the ball underhand to the child aiming at the child’s chest area. Tell the child to catch the ball with two hands. Only count a trial in which the toss is near child’s chest. Repeat a second trial.',
        criteria: [
            'Child’s hands are positioned in front of the body with the elbows flexed',
            'Arms extend reaching for the ball as it arrives',
            'Ball is caught by hands only',
        ],
        images: [
            '/images/home/assessments/catch/1.png',
            '/images/home/assessments/catch/2.png',
            '/images/home/assessments/catch/3.png',
        ],
    },
]

let index = 0

export function AssessmentProcedure() {
    const [showData, setShowData] = useState(data[index])

    function handleLeftClick() {
        if (index === 0) {
            index = data.length - 1
            setShowData(data[index])
            return
        }

        index = index - 1
        setShowData(data[index])
    }

    function handleRightClick() {
        if (index === data.length - 1) {
            index = 0
            setShowData(data[index])
            return
        }

        index = index + 1
        setShowData(data[index])
    }

    return (
        <ElementWithButton
            heading={
                <HeadingWrapper>
                    Assessment procedure
                    <HeadingButtonWrapper>
                        <HeadingButton onClick={handleLeftClick}>
                            <BsArrowLeft />
                        </HeadingButton>
                        <HeadingButton onClick={handleRightClick}>
                            <BsArrowRight />
                        </HeadingButton>
                    </HeadingButtonWrapper>
                </HeadingWrapper>
            }
        >
            <ContentWrapper>
                {showData.images.map((url, i) => {
                    return <Img src={url} key={i} />
                })}
                <TextWrapper>
                    <h1>{showData.title}</h1>
                    <div>{showData.description}</div>
                    <figure>
                        <figcaption>Criteria</figcaption>
                        <ul>
                            {showData.criteria.map((c, i) => {
                                return (
                                    <li key={i}>
                                        <small>{c}</small>
                                    </li>
                                )
                            })}
                        </ul>
                    </figure>
                </TextWrapper>
            </ContentWrapper>
        </ElementWithButton>
    )
}
