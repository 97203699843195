const apiUrl = process.env.REACT_APP_API_URL

export async function getReport(class_id: number, batch: number) {
    const res = await fetch(`${apiUrl}/report/${class_id}/${batch}`)
    const response: ReportItem[] = await res.json()

    return response
}

export interface ReportItem {
    skill: string
    result: Result
}

interface Result {
    achieving: number
    developing: number
    progressing: number
}
