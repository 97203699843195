import { styled } from 'styled-components'

interface Prop {
    setTitle: React.Dispatch<React.SetStateAction<string>>
}

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`

const Content = styled.div`
    padding: 50px 30px;
    display: flex;
    flex-direction: column;
    gap: 50px;
`

const Hero = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
`

const HeroContent = styled.div`
    padding: 30px 50px 0 0;
    line-height: 2.6;
`

const ProfileImage = styled.img`
    width: 169px;
    height: 168px;
`

const MeetTheTeam = styled.div`
    display: flex;
    flex-direction: column;
    gap: 50px;
`

const Profile = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
`
const ProfileGroup = styled.div`
    display: flex;
    justify-content: space-around;
`

const ProflieContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
`

const Collaborators = styled.div`
    padding-top: 30px;
    display: flex;
    justify-content: space-between;
`

export default function AboutUs({ setTitle }: Prop) {
    setTitle('About Us')
    return (
        <Wrapper>
            <Content>
                <div>
                    <h1>Move the Next Generation</h1>
                    <Hero>
                        <HeroContent>
                            <h3>
                                An AI automated movement skill assessment
                                solution
                            </h3>
                            <p>
                                Moving the The Gen automatically assesses and
                                reports on children's level of proficiency
                                across seven movement skills. This data will
                                allow you to plan and deliver targeted teaching
                                and learning programs based on the students’
                                movement competence, evaluate the impact of your
                                teaching, and adapt your teaching to better meet
                                the needs of all students.
                            </p>
                        </HeroContent>
                        <img src="./images/about-us/hero.png" />
                    </Hero>
                </div>
                <MeetTheTeam>
                    <h1>Meet the team</h1>
                    <ProfileGroup>
                        <Profile>
                            <ProfileImage src="./images/about-us/natalie-lander.png" />
                            <ProflieContent>
                                <p>A/Professor Natalie Lander,</p>
                                <p>IPAN, Deakin Univerisity</p>
                                <p>
                                    Bio:{' '}
                                    <a href="https://experts.deakin.edu.au/29054-Natalie-Lander">
                                        Natalie Lander | Deakin
                                    </a>
                                </p>
                            </ProflieContent>
                        </Profile>
                        <Profile>
                            <ProfileImage src="./images/about-us/lisa-barnett.png" />
                            <ProflieContent>
                                <p>Professor Lisa Barnett,</p>
                                <p>IPAN, Deakin Univerisity</p>
                                <p>
                                    Bio:{' '}
                                    <a href="https://experts.deakin.edu.au/803-Lisa-Barnett">
                                        Lisa Barnett | Deakin
                                    </a>
                                </p>
                            </ProflieContent>
                        </Profile>
                    </ProfileGroup>
                    <ProfileGroup>
                        <Profile>
                            <ProfileImage src="./images/about-us/tao-zhou.png" />
                            <ProflieContent>
                                <p>Doctor Tao Zhou,</p>
                                <p>IISRI, Deakin Univerisity</p>
                                <p>
                                    Bio:{' '}
                                    <a href="https://experts.deakin.edu.au/50999-Tao-Zhou">
                                        Tao Zhou | Deakin
                                    </a>
                                </p>
                            </ProflieContent>
                        </Profile>
                        <Profile>
                            <ProfileImage src="./images/about-us/yuxin-zhang.png" />
                            <ProflieContent>
                                <p>Doctor Yuxin Zhang,</p>
                                <p>IPAN, Deakin Univerisity</p>
                                <p>
                                    Bio:{' '}
                                    <a href="https://experts.deakin.edu.au/57559-Yuxin-Zhang#tab__1--1">
                                        Yuxin Zhang | Deakin
                                    </a>
                                </p>
                            </ProflieContent>
                        </Profile>
                    </ProfileGroup>
                </MeetTheTeam>
                <div>
                    <h1>Our collaborators</h1>
                    <Collaborators>
                        <img src="./images/about-us/sport-and-recreation-victoria.jpg" />
                        <img src="./images/about-us/deakin.png" />
                        <img src="./images/about-us/ipan.png" />
                        <img src="./images/about-us/iisri.png" />
                    </Collaborators>
                </div>
            </Content>
        </Wrapper>
    )
}
