import { ReactNode } from 'react'
import { styled } from 'styled-components'

const Wrapper = styled.div`
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #88888880;
    z-index: 2;
    border-radius: 10px;

    display: flex;
    align-items: center;
    justify-content: center;
`

const ContentWrapper = styled.div`
    background: white;
    padding: 50px;
    border-radius: 10px;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 30px;
`

const ButtonWrapper = styled.div`
    display: flex;
    gap: 50px;
`

const StopButton = styled.button`
    all: unset;
    cursor: pointer;
    background-color: #ed6a5e;
    color: white;
    padding: 10px;
    display: flex;
    justify-content: center;
    border-radius: 8px;
    &:hover {
        cursor: pointer;
        background-color: #ed6a5e90;
    }
`
const SubmitButton = styled.button`
    all: unset;
    cursor: pointer;
    background-color: #115ff5;
    color: white;
    padding: 10px;
    display: flex;
    justify-content: center;
    border-radius: 8px;
    &:hover {
        cursor: pointer;
        background-color: #115ff590;
    }
`

interface Props {
    children: ReactNode
    onCancel: () => void
    onConfrim: () => void
}

export function DoubleButtonModal({ children, onCancel, onConfrim }: Props) {
    return (
        <Wrapper>
            <ContentWrapper>
                {children}
                <ButtonWrapper>
                    <StopButton onClick={() => onCancel()}>Cancel</StopButton>
                    <SubmitButton onClick={() => onConfrim()}>
                        Confirm
                    </SubmitButton>
                </ButtonWrapper>
            </ContentWrapper>
        </Wrapper>
    )
}

interface SingleButtonModalProps {
    children: ReactNode
    onCancel: () => void
}

export function SingleButtonModal({
    children,
    onCancel,
}: SingleButtonModalProps) {
    return (
        <Wrapper>
            <ContentWrapper>
                {children}
                <SubmitButton onClick={() => onCancel()}>Ok</SubmitButton>
            </ContentWrapper>
        </Wrapper>
    )
}

interface NoButtonModalProps {
    children: ReactNode
}

export function NoButtonModal({ children }: NoButtonModalProps) {
    return (
        <Wrapper>
            <ContentWrapper>{children}</ContentWrapper>
        </Wrapper>
    )
}
