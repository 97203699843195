const apiUrl = process.env.REACT_APP_API_URL

export default async function getSkills() {
    const res = await fetch(`${apiUrl}/skills`)
    const response: Response = await res.json()

    return response
}

export interface Skill {
    id: string
    name: string
    description: string
}

interface Response {
    skills: Skill[]
}
