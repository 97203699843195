import { styled } from 'styled-components'
import Equipment from '../components/Equipment'
import Element from '../components/Element'
import { AssessmentProcedure } from '../components/AssessmentProcedure'

const Wrapper = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 50px;
    padding: 50px 0;
    align-items: center;
`

const Hero = styled.div`
    width: 90%;
    height: 260px;
    background-color: #e7effe;

    box-sizing: border-box;
    border: 1px solid #000000;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
`

const Text = styled.p`
    font-family: 'Inter';
    font-style: normal;
`

const ElementWrapper = styled.div`
    display: flex;
    width: 90%;
    gap: 30px;
`
const HeroContent = styled.div`
    padding: 40px;
`

const LearnMoreButton = styled.div`
    color: white;
    background-color: #115ff5;
    padding: 10px 20px;
    width: 100px;
    display: flex;
    justify-content: center;
    border-radius: 5px;
    margin-top: 60px;
`

const HeroImage = styled.img`
    position: relative;
    height: 300px;
    left: -10%;
`

const AssessmentSetUpWrapper = styled.div`
    display: flex;
`

const AssessmentContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 10px 40px;
`

const Img = styled.img`
    width: 640px;
`
interface Prop {
    setTitle: React.Dispatch<React.SetStateAction<string>>
}

export default function Home({ setTitle }: Prop) {
    setTitle('Get Started')

    return (
        <Wrapper>
            <Hero>
                <HeroContent>
                    <Text>Welcome to,</Text>
                    <h1>Move the Next Generation</h1>
                    <div>Your automated movement skill assessment solution</div>
                    <LearnMoreButton>Learn More</LearnMoreButton>
                </HeroContent>
                <HeroImage src="/images/home/hero.png" />
            </Hero>
            <ElementWrapper>
                <Element heading="About Move the Next Gen">
                    <iframe
                        width="580"
                        height="315"
                        src="https://www.youtube.com/embed/CatmN0c99XY?si=U8IL-nAXphu547qr"
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    ></iframe>
                </Element>
                <Equipment />
            </ElementWrapper>
            <Element heading="Assessment set up">
                <AssessmentSetUpWrapper>
                    <Img src="/images/assessment/hero.png" />
                    <AssessmentContentWrapper>
                        <div>
                            Set up skill assessment station prior to class. 
                        </div>
                        <div>
                            Ensure assessment  space is flat, clear, and safe.
                        </div>
                        <div>
                            Ensure filming space is free of distractions. 
                        </div>
                        <div>
                            Set video recorder / device up to capture the
                            child’s performance of the skill from a side on
                            position.  
                        </div>
                        <div>
                            Video must capture the entire performance of the
                            skill, but does not need to track the ball (for
                            catch, kick and throw).
                        </div>
                    </AssessmentContentWrapper>
                </AssessmentSetUpWrapper>
            </Element>
            <AssessmentProcedure />
        </Wrapper>
    )
}
