import { useEffect, useState } from 'react'
import { getProfile, postLogin, postLogout } from '../libs/login-service'
import { useNavigate } from 'react-router-dom'
import { styled } from 'styled-components'

const Wrapper = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
`
const Img = styled.img`
    width: 50%;
    height: 100%;
    object-fit: cover;
`
const ContentWrapper = styled.div`
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`

const Logo = styled.img`
    width: 120px;
`

const Title = styled.h1`
    color: #115ff5;
`

const Input = styled.input`
    height: 40px;
    width: 400px;
    padding: 5px 15px;
    broder: 1px solid #a4a2a1;
`

const InputWrapper = styled.div`
    padding: 20px 0;
`

const InputLabel = styled.p`
    color: #a4a2a1;
    padding: 10px 0;
`

const RegisterWrapper = styled.div`
    display: flex;
    justify-content: center;
    padding: 30px;
`

const Button = styled.input<{ active?: boolean }>`
    padding: 10px;
    width: 100%;
    margin: 20px 0;
    background-color: ${(props) => (props.active ? '#115ff5' : '#717171')};
    border: none;
    border-radius: 5px;
    color: white;
`

interface Props {
    setIsLoggedIn: React.Dispatch<React.SetStateAction<boolean>>
}

export default function Login({ setIsLoggedIn }: Props) {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [active, setActive] = useState<boolean>(
        email !== '' && password !== ''
    )
    const [status, setStatus] = useState<'normal' | 'fail'>('normal')
    const navigate = useNavigate()

    useEffect(() => {
        setActive(email !== '' && password !== '')
    }, [email, password])

    async function onSubmit() {
        const body = {
            email: email,
            password: password,
        }
        const { success, userId } = await postLogin(body)

        if (success) {
            setIsLoggedIn(true)

            localStorage.setItem('userId', JSON.stringify(userId))

            navigate('/')
        } else {
            setStatus('fail')
        }
    }

    return (
        <Wrapper>
            <Img src="/images/login/login.png" />
            <ContentWrapper>
                <div>
                    <div>
                        <Logo src="/images/login/logo.png" />
                        <Title>Welcome to MNG</Title>
                    </div>
                    <p>Automated movement skill assessment solution</p>
                    <InputWrapper>
                        <InputLabel>Account</InputLabel>
                        <Input
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Enter your email...."
                        />
                    </InputWrapper>
                    <InputWrapper>
                        <InputLabel>Password</InputLabel>
                        <Input
                            type="password"
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="Enter your password...."
                        />
                    </InputWrapper>
                    <Button
                        type="button"
                        value="Login"
                        active={active}
                        onClick={() => onSubmit()}
                    />
                    <RegisterWrapper>
                        Don’t have an account yet?&nbsp;
                        <a href="register">Sign Up</a>
                    </RegisterWrapper>
                </div>
            </ContentWrapper>
        </Wrapper>
    )
}
