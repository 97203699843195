import { Bar, getElementAtEvent } from 'react-chartjs-2'
import 'chart.js/auto'
import CharWrapper from '../ChartWrapper'
import { ChartData, ChartTypeRegistry } from 'chart.js/auto'
import { useRef, MouseEvent } from 'react'

interface Props {
    heading: string
    data: ChartData<'bar', (number | [number, number] | null)[], unknown>
    handleBarClick: (dataIndex: number) => void
}

export default function StudentBarChart({
    heading,
    data,
    handleBarClick,
}: Props) {
    const chartRef = useRef<any>()
    const onClick = (event: MouseEvent<HTMLCanvasElement>) => {
        const element = getElementAtEvent(chartRef.current, event)
        if (element.length === 0) {
            return
        }
        const dataIndex = element[0].index
        handleBarClick(dataIndex)
    }

    // TODO: make the max scale dynmaic
    // Currently hardcoded same as assessment.py in backend
    let yMaxScale = 3

    if (
        [
            'Criteria achieved for Jump',
            'Criteria achieved for Hop',
            'Criteria achieved for Throw',
            'Criteria achieved for Kick',
        ].includes(heading)
    ) {
        yMaxScale = 4
    }

    const options = {
        plugins: {},
        legend: {
            position: 'bottom' as const,
        },
        scales: {
            y: {
                title: {
                    display: true,
                    text: 'Number of criteria',
                },
                suggestedMin: 0,
                suggestedMax: yMaxScale,
                ticks: {
                    stepSize: 1,
                },
            },
        },
    }

    return (
        <CharWrapper heading={heading}>
            <Bar
                ref={chartRef}
                options={options}
                data={data}
                onClick={onClick}
            />
        </CharWrapper>
    )
}
