const apiUrl = process.env.REACT_APP_API_URL

export default async function postVideo(body: FormData) {
    const videoResponse = await fetch(`${apiUrl}/video`, {
        method: 'POST',
        body: body,
    })
    const videoJsonResponse: VideoResponse = await videoResponse.json()

    return videoJsonResponse
}

export async function processVideo(assessmentId: number) {
    const videoResponse = await fetch(`${apiUrl}/process/${assessmentId}`)

    const videoJsonResponse: Response = await videoResponse.json()

    return videoJsonResponse
}

interface VideoResponse {
    videoPath: string
}

export function getVideoUrl(path: string) {
    return `${apiUrl}/static${path}`
}

export function getOutUrl(path: string) {
    return `${apiUrl}/static${path}`
}
