import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import StudentInfo from '../components/StudentInfo'
import AssemssmentsInfo from '../components/AssessmentInfo'
import { getStudentById } from '../libs/student-service'
import {
    Assessment,
    getAssessmentsByStudentId,
} from '../libs/assessment-service'
import { Student as StudentModel } from '../libs/student-service'

const Wrapper = styled.div`
    width: 100%;
    padding: 30px 0;
`
interface Prop {
    setTitle: React.Dispatch<React.SetStateAction<string>>
}
export default function Student({ setTitle }: Prop) {
    setTitle('Report')

    const { id } = useParams()
    const [student, setStudent] = useState<StudentModel>()
    const [assessments, setAssessments] = useState<Assessment[]>([])

    useEffect(() => {
        if (!id) {
            return
        }

        const fetchStudent = async () => {
            const student = await getStudentById(id)

            setStudent(student)
        }

        const fetchAssessments = async () => {
            const assessments = await getAssessmentsByStudentId(id)

            setAssessments(assessments)
        }

        fetchStudent()
        fetchAssessments()
    }, [])

    if (student) {
        return (
            <Wrapper>
                <StudentInfo student={student} />
                <AssemssmentsInfo assessments={assessments} />
            </Wrapper>
        )
    }

    return <>Loading Student info</>
}
