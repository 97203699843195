import { BrowserRouter, Routes, Route, useNavigate } from 'react-router-dom'
import Home from './pages/Home'
import Assessment from './pages/Assessment'
import Report from './pages/Report'
import Education from './pages/Education'
import AboutUs from './pages/AboutUs'
import Layout from './pages/Layout'
import Student from './pages/Student'
import Login from './pages/Login'
import Register from './pages/Register'
import { createContext, useEffect, useMemo, useState } from 'react'
import { Class, getProfile } from './libs/login-service'

export const ClassContext = createContext<Class[]>([])

function App() {
    const [title, setTitle] = useState('Get Started')
    const [classes, setClasses] = useState<Class[]>([])
    const [isLoggedIn, setIsLoggedIn] = useState(false)

    useEffect(() => {
        const fetchProfile = async (userId: number) => {
            const profile = await getProfile(userId)
            setClasses(profile.classes)
        }

        const userIdJson = localStorage.getItem('userId')

        if (userIdJson) {
            const userId = JSON.parse(userIdJson)
            fetchProfile(userId)
        }
    }, [])

    return (
        <ClassContext.Provider value={classes}>
            <BrowserRouter>
                <Routes>
                    <Route
                        path="/"
                        element={
                            <Layout
                                title={title}
                                setIsLoggedIn={setIsLoggedIn}
                                setClasses={setClasses}
                            />
                        }
                    >
                        <Route index element={<Home setTitle={setTitle} />} />
                        <Route
                            path="assessment"
                            element={<Assessment setTitle={setTitle} />}
                        />
                        <Route
                            path="report"
                            element={<Report setTitle={setTitle} />}
                        />
                        <Route
                            path="student/:id"
                            element={<Student setTitle={setTitle} />}
                        />
                        <Route
                            path="education"
                            element={<Education setTitle={setTitle} />}
                        />
                        <Route
                            path="aboutus"
                            element={<AboutUs setTitle={setTitle} />}
                        />
                    </Route>
                    <Route
                        path="login"
                        element={<Login setIsLoggedIn={setIsLoggedIn} />}
                    />
                    <Route path="register" element={<Register />} />
                </Routes>
            </BrowserRouter>
        </ClassContext.Provider>
    )
}

export default App
