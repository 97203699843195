import { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import getStudents, { Student } from '../libs/student-service'
import StudentTable from '../components/StudentTable'
import Element from '../components/Element'
import { Class } from '../libs/login-service'
import ClassSelector from '../components/ClassSelector'
import { getReport, ReportItem } from '../libs/report-service'
import { Batch, getBatches } from '../libs/batch-service'
import { ClassContext } from '../App'
import ReportBarChart from '../components/report/ReportBarChart'

const Wrapper = styled.div`
    padding: 40px 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    width: 100%;
`

const ElementWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    width: 90%;
`
const BlueBackground1 = styled.div`
    height: 80px;
    background-color: #e7effe;
    display: flex;
    padding: 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
`

const BlueBackground2 = styled.div`
    height: 80px;
    background-color: #e7effe;
    display: flex;
    padding: 20px;
    justify-content: center;
    align-items: center;
    gap: 50px;
`

const HeroWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    width: 90%;
    gap: 20px;
`

const ElementContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: 30px;
`
const Content = styled.label`
    text-align: left;
`

const Badge = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
`

const Img = styled.img`
    height: 40px;
`

const AchievingLabel = styled.p`
    color: #00b88c;
`
const ProgressingLabel = styled.p`
    color: #f4bd50;
`

const DevelopingLabel = styled.p`
    color: #ed6a5e;
`

const HoveringText = styled.p`
    position: absolute;
    margin-top: -50px;
    color: white;
    background-color: grey;
    padding: 10px;
    border-radius: 8px;
`

interface Prop {
    setTitle: React.Dispatch<React.SetStateAction<string>>
}
export default function Report({ setTitle }: Prop) {
    useEffect(() => {
        setTitle('Report')
    }, [])
    const classes = useContext(ClassContext)
    const [students, setStudents] = useState<Student[]>([])
    const [report, setReport] = useState<ReportItem[]>([])
    const [selectedClass, setSelectedClass] = useState<Class>(classes[0])
    const [batch, setBatch] = useState<Batch | undefined>(
        selectedClass?.batches[0]
    )
    const [achievingHidden, setAchievingHidden] = useState(true)
    const [progressingHidden, setProgressingHidden] = useState(true)
    const [developingHidden, setDevelopingHidden] = useState(true)

    useEffect(() => {
        const fetchStudents = async () => {
            if (!selectedClass || !batch) {
                return
            }

            const { students } = await getStudents(selectedClass.id, batch.id)
            setStudents(students)
        }

        const fetchReport = async () => {
            if (!selectedClass || !batch) {
                return
            }

            const report = await getReport(selectedClass.id, batch.id)
            setReport(report)
        }

        fetchStudents()
        fetchReport()
    }, [selectedClass, batch])

    function handleClassUpdate(c: Class) {
        setSelectedClass(c)
        if (c.batches.length !== 0) {
            setBatch(c.batches[0])
        }
    }

    return (
        <Wrapper>
            <HeroWrapper>
                <Element heading="Scoring">
                    <ElementContent>
                        <BlueBackground1>
                            <img src="/images/report/scoring.png" />
                            <p>
                                The total score is the number of criteria that
                                the student demonstrated in each skill.
                            </p>
                        </BlueBackground1>
                        <Content>
                            Each skill is assessed on three to five specific
                            performance criteria per skill; and scored 1 or 0
                            based on whether the criterion was fulfilled or not.
                            The higher the score the more proficient the
                            performance.
                        </Content>
                    </ElementContent>
                </Element>
                <Element heading="Outcome">
                    <ElementContent>
                        <BlueBackground2>
                            <Badge
                                onMouseEnter={() => setAchievingHidden(false)}
                                onMouseLeave={() => setAchievingHidden(true)}
                            >
                                {achievingHidden === false && (
                                    <HoveringText>
                                        Achieving: All criteria met
                                    </HoveringText>
                                )}
                                <Img src="/images/report/achieving.png" />
                                <AchievingLabel>Achieving</AchievingLabel>
                            </Badge>
                            <Badge
                                onMouseEnter={() => setProgressingHidden(false)}
                                onMouseLeave={() => setProgressingHidden(true)}
                            >
                                {progressingHidden === false && (
                                    <HoveringText>
                                        Progressing: All but one criterion met
                                    </HoveringText>
                                )}
                                <Img src="/images/report/progressing.png" />
                                <ProgressingLabel>Progressing</ProgressingLabel>
                            </Badge>
                            <Badge
                                onMouseEnter={() => setDevelopingHidden(false)}
                                onMouseLeave={() => setDevelopingHidden(true)}
                            >
                                {developingHidden === false && (
                                    <HoveringText>
                                        Developing: Two or more criteria not met
                                    </HoveringText>
                                )}
                                <Img src="/images/report/developing.png" />
                                <DevelopingLabel>Developing</DevelopingLabel>
                            </Badge>
                        </BlueBackground2>
                        <Content>
                            Each skill score will be converted to a descriptive
                            outcome based on the number of criteria achieved.{' '}
                            Summary scores are provided for locomotor skills
                            (skip, hop, jump, side step), object control skills
                            (catch, kick, throw) and total skills per student.
                        </Content>
                    </ElementContent>
                </Element>
            </HeroWrapper>

            <ClassSelector
                classes={classes}
                selectedClass={selectedClass}
                setSelectedClass={handleClassUpdate}
                setBatch={setBatch}
            />

            <StudentTable
                selectedClass={selectedClass}
                batch={batch}
                setStudent={() => {}}
                badgeClick={() => {}}
            />

            {report.map((r, i) => {
                if (i % 2 === 0) {
                    return (
                        <ElementWrapper>
                            {report && report[i] && (
                                <ReportBarChart reportItem={report[i]} />
                            )}
                            {report && report[i + 1] && (
                                <ReportBarChart reportItem={report[i + 1]} />
                            )}
                        </ElementWrapper>
                    )
                }
            })}
        </Wrapper>
    )
}
