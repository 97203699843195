import { useEffect, useState } from 'react'
import styled from 'styled-components'
import getSkills, { Skill } from '../libs/skill-service'

const Wrapper = styled.div`
    grid-area: skills;
    display: flex;
    justify-content: space-between;
    gap: 10px;
    max-width: 800px;
    max-height: 40px;
`

const SkillButton = styled.div<{ $isActive: boolean }>`
    padding: 10px;
    border: 1px solid;

    &:hover {
        border: 2px solid green;
        cursor: pointer;
    }

    background: ${(props) => (props.$isActive === true ? 'green' : 'white')};
`

interface Props {
    skill: string
    setSkill: React.Dispatch<React.SetStateAction<string>>
}

export default function Skills({ skill, setSkill }: Props) {
    const [skills, setSkills] = useState<Skill[]>([])

    useEffect(() => {
        const fetchSkills = async () => {
            const { skills } = await getSkills()
            setSkills(skills)
            setSkill(skills[0].name)
        }
        fetchSkills()
    }, [])

    return (
        <Wrapper>
            <select
                onChange={(e) => setSkill(skills[e.target.selectedIndex].name)}
            >
                {skills.map((s, i) => {
                    const isActive = skill === s.name ? true : false
                    return (
                        <option key={i} selected={skill === s.name}>
                            {s.name}
                        </option>
                    )
                })}
            </select>
        </Wrapper>
    )
}
