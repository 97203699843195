import { Bar } from 'react-chartjs-2'
import 'chart.js/auto'
import { ReportItem } from '../../libs/report-service'
import CharWrapper from '../ChartWrapper'

interface Props {
    reportItem: ReportItem
}

export default function ReportBarChart({ reportItem }: Props) {
    const labels = Object.keys(reportItem.result)
    const values = Object.values(reportItem.result)

    const data = {
        labels,
        datasets: [
            {
                label: reportItem.skill,
                data: values,
            },
        ],
    }

    return (
        <CharWrapper heading={reportItem.skill}>
            <Bar options={options} data={data} />
        </CharWrapper>
    )
}

export const options = {
    plugins: {},
    legend: {
        position: 'bottom' as const,
    },
    scales: {
        y: {
            min: 0,
            max: 1,
            ticks: {
                callback: function (value: number | string) {
                    return Number(value) * 100 + '%'
                },
                beginAtZero: true,
            },
        },
    },
}
