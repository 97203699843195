import { useCallback, useState } from 'react'
import { useDropzone, FileWithPath } from 'react-dropzone'
import uploadStudents from '../libs/student-upload-service'
import { MdOutlineFileUpload } from 'react-icons/md'
import { styled } from 'styled-components'

interface Props {
    classId: number
}

const UploadWrapper = styled.div`
    width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
`

const Button = styled.input`
    padding: 10px;
    width: 100%;
    margin: 20px 0;
    background-color: #115ff5;
    border: none;
    border-radius: 5px;
    color: white;
`

export default function FileUploader({ classId }: Props) {
    const [isFileLoaded, setIsFileLoaded] = useState(false)
    const [isFileUploaded, setIsFileUploaded] = useState(false)
    const [file, setFile] = useState<File | null>(null)
    const onDrop = useCallback((acceptedFiles: FileWithPath[]) => {
        setFile(acceptedFiles[0])
        setIsFileLoaded(true)
    }, [])

    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        onDrop,
    })

    const files = acceptedFiles.map((file: FileWithPath) => (
        <li key={file.path}>
            {file.path} - {file.size} bytes
        </li>
    ))

    async function handleUploadClick() {
        if (!file) {
            return
        }

        var body = new FormData()
        body.append('file', file)

        const { success } = await uploadStudents(body, classId)

        setIsFileUploaded(success)
    }

    return (
        <section className="container">
            {isFileLoaded ? (
                <UploadWrapper>
                    <h4>Files</h4>
                    <ul>{files}</ul>
                    <Button
                        type="button"
                        onClick={() => handleUploadClick()}
                        value="Upload file"
                    />
                    {isFileUploaded && 'File uploaded successfully'}
                </UploadWrapper>
            ) : (
                <div {...getRootProps({ className: 'dropzone' })}>
                    <input {...getInputProps()} />
                    <UploadWrapper>
                        <MdOutlineFileUpload />
                        Drag & drop your finished fom here or choose a file to
                        files
                    </UploadWrapper>
                </div>
            )}
        </section>
    )
}
