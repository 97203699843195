import { useEffect, useState } from 'react'
import { Outlet, Link } from 'react-router-dom'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { BsChevronDown, BsChevronUp } from 'react-icons/bs'
import { RiLayoutMasonryFill } from 'react-icons/ri'
import { FaRunning } from 'react-icons/fa'
import { GiGraduateCap } from 'react-icons/gi'
import { RiUserSmileLine } from 'react-icons/ri'
import { MdOutlineAssessment } from 'react-icons/md'
import { Class, getProfile } from '../libs/login-service'

const Wrapper = styled.div`
    display: flex;
    min-height: 100vh;
    max-width: 100vw;
    overflow-x: hidden:
`

const Heading = styled.h3`
    padding: 30px;
    font-style: italic;
`

const Separator = styled.hr`
    width: 100%;
    background-image: -webkit-gradient(
        linear,
        left top,
        right top,
        from(#403a41),
        to(rgba(0, 0, 0, 0))
    );
    background-image: -webkit-linear-gradient(left, #403a41, rgba(0, 0, 0, 0));
    background-image: -moz-linear-gradient(left, #403a41, rgba(0, 0, 0, 0));
    background-image: -ms-linear-gradient(left, #403a41, rgba(0, 0, 0, 0));
    background-image: -o-linear-gradient(left, #403a41, rgba(0, 0, 0, 0));
    height: 1px;
    border: 0;
`

const Nav = styled.nav`
    padding: 20px 0;
    width: 15%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fafafa;
    box-shadow: 1px 0px 4px rgba(0, 0, 0, 0.12);
    z-index: 1;
`

const Ul = styled.ul`
    list-style-type: none;
    margin: 0;
    padding: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
`

const Li = styled.li`
    padding: 12px 0;
`

const Item = styled(Link)<{ isSelected: boolean }>`
    text-decoration: none;
    padding: 10px 30px;
    display: flex;
    align-items: center;
    gap: 10px;
    color: #717171;

    ${({ isSelected }) =>
        isSelected &&
        `
    color: #115ff5;
    font-weight: bold;
    border-right: 3px solid #115ff5; `}
`

const Body = styled.div`
    width: 85%;
    height: 100%;
    display: flex;
    flex-direction: column;
`

const HeaderWrapper = styled.div`
    width: 100%;
    background-color: #fafafa;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
`

const Header = styled.div`
    padding: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`

const UserInfo = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`
const HeadIcon = styled.img`
    width: 50px;
    height: 50px;
    border-radius: 50%;
`

const UserMenu = styled.div`
    position: absolute;
    border: 1px solid black;
    padding: 10px 20px;
    right: 50px;
    top: 100px;
`

interface Prop {
    title: string
    setIsLoggedIn: React.Dispatch<React.SetStateAction<boolean>>
    setClasses: React.Dispatch<React.SetStateAction<Class[]>>
}

const Layout = ({ title, setIsLoggedIn, setClasses }: Prop) => {
    const [showUserMenu, setShowUserMenu] = useState(false)
    const navigate = useNavigate()
    const [username, setUsernmae] = useState('Default User Name')

    useEffect(() => {
        const fetchProfile = async (userId: number) => {
            const profile = await getProfile(userId)
            setUsernmae(`${profile.first_name} ${profile.last_name}`)
            setClasses(profile.classes)
        }

        const userIdJson = localStorage.getItem('userId')

        if (userIdJson) {
            const userId = JSON.parse(userIdJson)
            fetchProfile(userId)
            setIsLoggedIn(true)
        } else {
            navigate('/login')
        }
    }, [])

    function logout() {
        localStorage.removeItem('userId')
        navigate('/login')
    }

    return (
        <Wrapper>
            <Nav>
                <Heading>Moving the Next Gen</Heading>
                <Separator />
                <Ul>
                    <Li>
                        <Item to="/" isSelected={title === 'Get Started'}>
                            <RiLayoutMasonryFill />
                            Get Started
                        </Item>
                    </Li>
                    <Li>
                        <Item
                            to="/assessment"
                            isSelected={title === 'Movement Skill Assessment'}
                        >
                            <FaRunning />
                            Assessment
                        </Item>
                    </Li>
                    <Li>
                        <Item to="/report" isSelected={title === 'Report'}>
                            <MdOutlineAssessment />
                            Report
                        </Item>
                    </Li>
                    <Li>
                        <Item
                            to="/education"
                            isSelected={title === 'Education'}
                        >
                            <GiGraduateCap />
                            Education
                        </Item>
                    </Li>
                    <Li>
                        <Item to="/aboutus" isSelected={title === 'About Us'}>
                            <RiUserSmileLine />
                            About Us
                        </Item>
                    </Li>
                </Ul>
            </Nav>

            <Body>
                <HeaderWrapper>
                    <Header>
                        <b>{title}</b>
                        <UserInfo
                            onClick={() => setShowUserMenu(!showUserMenu)}
                        >
                            <HeadIcon src="/profile.webp" />
                            {username}
                            {showUserMenu && (
                                <UserMenu onClick={() => logout()}>
                                    Log out
                                </UserMenu>
                            )}
                            {showUserMenu ? <BsChevronUp /> : <BsChevronDown />}
                        </UserInfo>
                    </Header>
                </HeaderWrapper>
                <Outlet />
            </Body>
        </Wrapper>
    )
}

export default Layout
