import { Assessment } from './assessment-service'

const apiUrl = process.env.REACT_APP_API_URL

export default async function getStudents(classId: number, batch_id: number) {
    const res = await fetch(`${apiUrl}/students/${classId}/${batch_id}`)
    const response: Response = await res.json()

    return response
}

export async function getStudentById(id: string) {
    const res = await fetch(`${apiUrl}/student/${id}`)
    const response: Student = await res.json()

    return response
}

interface Response {
    students: Student[]
}

export interface Student {
    id: string
    first_name: string
    last_name: string
    email: string
    student_id: string
    gender: string
    class: string
    class_name: string
    assessments: Assessment[]
}
