import { styled } from 'styled-components'
import { Class } from '../libs/login-service'
import Batches from './Batches'
import { Batch, postBatch } from '../libs/batch-service'
import { useState } from 'react'

const Wrapper = styled.div`
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const ElementWrapper = styled.div`
    border-radius: 5px;
    display: flex;
    gap: 10px;
`
const ClassWrapper = styled.div<{ selected: boolean }>`
    width: 120px;
    display: flex;
    justify-content: center;
    padding: 10px;
    border-radius: 5px;
    background-color: #e7effe;
    color: #115ff5;

    ${({ selected }) =>
        selected &&
        `
    background-color: #115ff5; 
    color: white;`}
`
interface Props {
    classes: Class[]
    setSelectedClass: (c: Class) => void
    selectedClass: Class
    setBatch: React.Dispatch<React.SetStateAction<Batch | undefined>>
}

export default function ClassSelector({
    classes,
    selectedClass,
    setSelectedClass,
    setBatch,
}: Props) {
    if (!selectedClass) {
        return <div></div>
    }
    return (
        <Wrapper>
            <ElementWrapper>
                {classes.map((c, i) => {
                    return (
                        <ClassWrapper
                            key={i}
                            onClick={() => setSelectedClass(classes[i])}
                            selected={selectedClass === classes[i]}
                        >
                            {c.name}
                        </ClassWrapper>
                    )
                })}
            </ElementWrapper>
            <Batches setBatch={setBatch} batches={selectedClass.batches} />
        </Wrapper>
    )
}
