import styled from 'styled-components'
import { Batch, getBatches } from '../libs/batch-service'

const Wrapper = styled.div`
    grid-area: skills;
    display: flex;
    justify-content: space-between;
    gap: 10px;
    max-width: 800px;
    max-height: 40px;
    padding: 10px;
`
const Select = styled.select`
    padding: 5px;
    font-size: 15px;
`

interface Props {
    setBatch: React.Dispatch<React.SetStateAction<Batch | undefined>>
    batches: Batch[]
}

export default function Batches({ batches, setBatch }: Props) {
    return (
        <Wrapper>
            <Select onChange={(e) => setBatch(batches[e.target.selectedIndex])}>
                {batches.map((s, i) => {
                    return <option key={i}>{s.name}</option>
                })}
            </Select>
        </Wrapper>
    )
}
