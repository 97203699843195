import { useState } from 'react'
import postRegister from '../libs/register-service'
import { styled } from 'styled-components'
import getClassListDownloadUrl from '../libs/download-service'
import StudentUploader from '../components/StudentUploader'
import { DropDownList, Input, PasswordInput } from '../components/Input'
import { SingleButtonModal } from '../components/shared/Modal'

const Wrapper = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
`
const Img = styled.img`
    width: 50%;
    height: 100%;
    object-fit: cover;
`
const ContentWrapper = styled.div`
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`

const Title = styled.h1`
    color: #115ff5;
    margin-bottom: 50px;
`

const InternalWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 30px;
`

const NameWrapper = styled.div`
    display: flex;
    gap: 30px;
`

const SubmitButton = styled.button`
    all: unset;
    cursor: pointer;
    height: 45px;
    background-color: #115ff5;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    &:hover {
        cursor: pointer;
        background-color: #115ff590;
    }
    margin: 30px 0;
`

export default function Register() {
    const [accountId, setAccountId] = useState(0)
    const [status, setStatus] = useState<'normal' | 'success' | 'fail'>(
        'normal'
    )

    const classListUrl = getClassListDownloadUrl()

    return (
        <Wrapper>
            <Img src="/images/login/login.png" />
            <ContentWrapper>
                <div>
                    <Title>Welcome on board</Title>
                    {status === 'success' ? (
                        <StudentUploader
                            teacherId={accountId}
                            classListUrl={classListUrl}
                        />
                    ) : (
                        <RegisterForm
                            setStatus={setStatus}
                            setAccountId={setAccountId}
                            status={status}
                        />
                    )}
                    <div>
                        <a href="login">Back</a>
                    </div>
                </div>
            </ContentWrapper>
        </Wrapper>
    )
}

interface Props {
    setStatus: React.Dispatch<
        React.SetStateAction<'normal' | 'success' | 'fail'>
    >
    setAccountId: React.Dispatch<React.SetStateAction<number>>
    status: string
}

function RegisterForm({ setStatus, setAccountId, status }: Props) {
    const [username, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [firstname, setFirstname] = useState('')
    const [lastname, setLastname] = useState('')
    const [role, setRole] = useState('')

    async function onSubmit() {
        const body = {
            email: username,
            password: password,
            firstname: firstname,
            lastname: lastname,
            role: role,
        }
        try {
            const { success, accountId } = await postRegister(body)

            if (success) {
                setStatus('success')
                setAccountId(accountId)
            } else {
                setStatus('fail')
            }
        } catch (e) {
            setStatus('fail')
        }
    }
    return (
        <InternalWrapper>
            {status === 'success' && (
                <p> register success, jumping to main page </p>
            )}
            {status === 'fail' && (
                <SingleButtonModal
                    children={'register failed, please try again'}
                    onCancel={() => setStatus('normal')}
                />
            )}
            <NameWrapper>
                <Input title="First Name" onChange={setFirstname} />
                <Input title="Last Name" onChange={setLastname} />
            </NameWrapper>
            <Input title="Email" onChange={setEmail} />
            <PasswordInput title="Password" onChange={setPassword} />
            <DropDownList title="Role" onChange={setRole} />
            <SubmitButton type="button" onClick={() => onSubmit()}>
                Submit
            </SubmitButton>
        </InternalWrapper>
    )
}
