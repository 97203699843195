import { styled } from 'styled-components'
import { Batch } from '../../libs/batch-service'
import { Student } from '../../libs/student-service'
import { DoubleButtonModal } from '../shared/Modal'

interface Props {
    batch: Batch | undefined
    skill: string
    student: Student | null
    handleCancel: () => void
    handleConfirm: () => void
}

export function ConfirmSubmitModal({
    handleCancel,
    handleConfirm,
    batch,
    skill,
    student,
}: Props) {
    return (
        <DoubleButtonModal
            children={
                <ConfirmMessage batch={batch} skill={skill} student={student} />
            }
            onCancel={handleCancel}
            onConfrim={handleConfirm}
        />
    )
}
interface ConfirmMessageProps {
    batch: Batch | undefined
    skill: string
    student: Student | null
}

const ConfirmMessageWrapper = styled.div`
    display: flex;
    flex-direction: column;
`

function ConfirmMessage({ batch, skill, student }: ConfirmMessageProps) {
    return (
        <ConfirmMessageWrapper>
            Do you want to sumbmit assessment for
            <b>Batch -{batch?.name}</b>
            <b>Skill - {skill}</b>
            <b>
                Student - {student?.first_name} {student?.last_name}{' '}
            </b>
        </ConfirmMessageWrapper>
    )
}
