import { ReactNode } from 'react'
import { styled } from 'styled-components'

const Header = styled.div`
    border-bottom: 1px solid grey;
    padding: 10px 20px;
`
const Body = styled.div`
    padding: 20px;
`
const ElementWrapper = styled.div`
    width: 49%;
    border: 1px solid grey;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
`
interface Props {
    heading: string
    children: ReactNode
}

export default function CharWrapper({ heading, children }: Props) {
    return (
        <ElementWrapper>
            <Header>{heading}</Header>
            <Body>{children}</Body>
        </ElementWrapper>
    )
}
