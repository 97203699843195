import { styled } from 'styled-components'

const InputBox = styled.input`
    height: 40px;
    width: 100%;
    border: 1px solid #a4a2a1;
`
const DropDown = styled.select`
    height: 40px;
    width: 100%;
    border: 1px solid #a4a2a1;
    background-color: transparent;
    padding: 10px;
`

const InputLabel = styled.p`
    color: #a4a2a1;
    padding: 10px 0;
`
interface Props {
    title: string
    onChange: React.Dispatch<React.SetStateAction<string>>
}

export function Input({ title, onChange }: Props) {
    return (
        <div>
            <InputLabel>{title} </InputLabel>
            <InputBox onChange={(e) => onChange(e.target.value)} />
        </div>
    )
}

export function DropDownList({ title, onChange }: Props) {
    return (
        <div>
            <InputLabel>{title} </InputLabel>
            <DropDown onChange={(e) => onChange('teacher')}>
                <option>teacher</option>
            </DropDown>
        </div>
    )
}

export function PasswordInput({ title, onChange }: Props) {
    return (
        <div>
            <InputLabel>{title} </InputLabel>
            <InputBox
                type="password"
                onChange={(e) => onChange(e.target.value)}
            />
        </div>
    )
}
